import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
  }

  onToggleForm(event) {
    const el = event.target.closest('tbody').querySelector('[data-form-url]')
    if (el) {
      Helpers.fetchAbortPrevious(this, 'get', el.dataset.formUrl).then(response => {
        if(response.ok) {
          response.html.then(html => {
            el.outerHTML = html
          })
        }
      })
    }
  }
}
