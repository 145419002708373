import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'jobs',
  ]

  connect() {
    this.update()
  }

  update() {
    if (this.hasJobsTarget) {
      const url = this.jobsTarget.dataset.jobsUrl

      Helpers.fetchAbortPrevious(this, 'get', url).then(response => {
        if(response.ok) {
          response.html.then(html => {
            this.jobsTarget.innerHTML = html

            if (this.jobsTarget.querySelector('[data-job-refresh]')) {
              setTimeout(() => this.update(), 5000)
            }
          })
        }
      })
    } // hasJobsTarget

  }
}
