import { Controller } from '@hotwired/stimulus'
import { Helpers, isVisible } from 'pistachio'

export default class extends Controller {
  static values = {
    'highlight': Number,
  }

  connect() {
    const highlightRow = this.highlightRow()

    if (highlightRow) {
      highlightRow.classList.add('bg-yellow-100')

      if (!isVisible(highlightRow)) {
        // expand handled requests part
        Helpers.emit(highlightRow, 'toggle')
      }

      // setTimeout makes the scrolling consistent
      setTimeout(() => highlightRow.scrollIntoView({ behavior: 'instant', block: 'center' }), 0)

      // remove for next page load or redirect
      const url = new URL(window.location.href)
      url.searchParams.delete('highlight')
      window.history.replaceState({}, '', url)
    }
  }

  highlightRow() {
    if (!this.highlightValue) {
      return
    }

    return this.element.querySelector(`[data-approval-request-id="${this.highlightValue}"]`)
  }
}
