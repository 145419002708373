import { Controller } from '@hotwired/stimulus'
import { Helpers, isVisible } from 'pistachio'

export default class extends Controller {
  connect() {
    if (!this.shouldWeDelayUntilVisible) {
      this.fetch()
    } else {
      this.interval = setInterval(() => {
        if (isVisible(this.element)) {
          this.fetch()
          clearInterval(this.interval)
        }
      }, 100)
    }
  }

  disconnect() {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }

  fetch() {
    Helpers.fetchAbortPrevious(this, 'get', this.url).then(response => {
      if(response.ok) {
        response.html.then(html => {
          this.targetElement.outerHTML = html
        })
      }
    })
  }

  get url() {
    return this.element.dataset.url
  }

  get targetSelector() {
    return this.element.dataset.targetSelector
  }

  get targetElement() {
    if(this.targetSelector) {
      return document.querySelector(this.targetSelector)
    }

    return this.element
  }

  get shouldWeDelayUntilVisible() {
    return this.element.hasAttribute('data-delay-until-visible')
  }
}
