import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  POLL_TIME_MILLISECONDS = 1000

  static targets = [
    'modal',
    'downloadLink',
  ]

  connect() {
    this.status = 'idle'
    this.update()
  }

  stop() {
    clearTimeout(this.pollingTimeout)
  }

  disconnect() {
    // make sure we stop polling
    this.stop()
  }

  onTrigger(event) {
    const triggerPath = this.element.dataset.triggerPath
    const triggerMethod = this.element.dataset.triggerMethod || 'GET'

    this.status = 'running'
    Helpers.emit(this.modalTarget, 'modal:open!')
    this.update()

    Helpers.fetch(triggerMethod, triggerPath).then(response => {
      if (response.ok) {
        response.text.then(token => {
          this.poll(token)
        })
      }
    })
  }

  poll(token) {
    Helpers.fetch('get', `${this.element.dataset.statusPath}?token=${token}`).then(response => {
      if (response.ok) {
        response.json.then(json => {
          if (json.done) {
            this.status = 'done'
            this.downloadLinkTarget.href = json.url
            this.update()
          } else {
            this.pollingTimeout = setTimeout(() => this.poll(token), this.POLL_TIME_MILLISECONDS)
          }
        })
      }
    })
  }

  update() {
    this.element.querySelectorAll('[data-status]').forEach(el => toggle(el, el.dataset.status === this.status))
  }
}
