import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  static targets = [
    'table',
  ]

  onRegionSet(event) {
    const url = this.element.dataset.tableUrl
    this.tableTarget.innerHTML = ''

    Helpers.fetchAbortPrevious(this, 'get', url, { query: { region: event.detail.value } }).then(response => {
      if(response.ok) {
        response.html.then(html => {
          this.tableTarget.innerHTML = html
        })
      }
    })
  }
}
