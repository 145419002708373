import { Controller } from '@hotwired/stimulus'
import { Helpers, toggle } from 'pistachio'

export default class extends Controller {
  static values = {
    userIds: Array,
    startDate: String,
    endDate: String,
    eventsUrl: String,
  }

  static targets = [
    'monthNavigation',
    'headerNavigation',
    'highlight',
    'calendar',
  ]

  connect() {
    this.observer = this.createHeaderStickyObserver()
    this.element.addEventListener('staff_calendar:refresh!', (event) => this.refreshUsers(event.detail.userIds))

    this.load()
  }

  disconnect() {
    this.observer.disconnect()
  }

  createHeaderStickyObserver() {
    const observer = new IntersectionObserver(([entry]) => toggle(this.monthNavigationTarget, entry.intersectionRatio < 0.5, 'flex'), {
        threshold: [0.5],
    })
    observer.observe(this.headerNavigationTarget)
    return observer
  }

  load() {
    const chunkSize = 5;

    for (let i = 0; i < this.userIdsValue.length; i += chunkSize) {
      const ids = this.userIdsValue.slice(i, i + chunkSize)
      this.refreshUsers(ids)
    }
  }

  refreshUsers(ids) {
    const params = {
      users: ids,
      indices: ids.map(id => this.userIdsValue.indexOf(id)),
      start_date: this.startDateValue,
      end_date: this.endDateValue,
    }

    Helpers.fetch('get', this.eventsUrlValue, { query: new URLSearchParams(Helpers.objectToQueryString(params)) }).then(response => {
      if(response.ok) {
        response.text.then(text => {
          ids.forEach(id => this.calendarTarget.querySelectorAll(`[data-user-id="${id}"]`).forEach(element => element.remove()))
          this.calendarTarget.insertAdjacentHTML('beforeend', text)

          if (this.hasHighlightTarget) {
            this.highlightTarget.click()
          }
        })
      }
    })
  }
}
