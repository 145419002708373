import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', (event) => {
      const source = event.target && event.target.closest('[data-remote-fetch-url]')
      if (!source)
        return

      const url = source.getAttribute('data-remote-fetch-url')
      const targetSelector = source.getAttribute('data-remote-fetch-target-selector')
      const target = targetSelector ? document.querySelector(targetSelector) : document.body
      if (!target)
        return

      this.load(target, url)

      event.stopPropagation()
      event.preventDefault()
    }, true)
  }

  load(target, url) {
    target.style.opacity = '0.5'

    Helpers.fetchAbortPrevious(this, 'get', url).then(response => {
      if(response.ok) {
        response.html.then(html => {
          target.outerHTML = html
          target.style.opacity = ''
        })
      }
    }).catch(error => {
      console.error(error)
    })
  }
}
