import { Controller } from '@hotwired/stimulus'
import { Helpers } from 'pistachio'

export default class extends Controller {
  connect() {
    this.element.addEventListener('click', event => {
      const url = this.element.getAttribute('href') || this.getAttribute('data-download-url')
      const reload = this.element.getAttribute('data-download-autoreload') ? true : false
      this.download(url, reload)
      event.preventDefault()
    })
  }

  download(url, reload) {
    const a = document.createElement('a')

    // fallback if download on url is not supported
    if (typeof a.download === 'undefined') {
      window.open(url)
      return
    }

    Helpers.fetch('get', url, { responseKind: 'blob' }).then(fetchResponse => {
      if (fetchResponse.ok) {
        fetchResponse.response.blob().then(blob => {
          const disposition = fetchResponse.headers.get('Content-Disposition')
          const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          const matches = filenameRegex.exec(disposition)

          let filename = 'export'
          if (matches != null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '')
          }

          const file = window.URL.createObjectURL(blob)
          a.href = file
          a.download = filename
          document.body.appendChild(a)
          a.click()

          if (reload) {
            window.location.reload(false)
          }
        })
      }
    })
  }
}
